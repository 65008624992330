/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui icons
import HourglassFullOutlinedIcon from "@mui/icons-material/HourglassFullOutlined";
// import TravelExploreOutlinedIcon from "@mui/icons-material/TravelExploreOutlined";
import { PlayArrow } from "@mui/icons-material";
// Pages
import ChatHome from "pages/ChatHome";
/// Sections

const routes = [
  {
    name: "Demo",
    icon: <PlayArrow></PlayArrow>,
    route: "/ChatHome",
    component: <ChatHome />
  },
  {
    name: "Join Waitlist",
    href: "https://clzcwdxbzxe.typeform.com/to/X0omsksZ",
    icon: <HourglassFullOutlinedIcon></HourglassFullOutlinedIcon>
    // route: "/",
    // component: <Home />
  }
];

export default routes;
