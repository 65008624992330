/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
// Material Kit 2 React examples
import MovieClip from "components/MovieClip";
import DefaultNavbar from "components/Navbars/DefaultNavbar";
import SimpleFooter from "components/SimpleFooter";
import Counters from "./sections/Counters";
// Presentation page sections

// Presentation page components

// Routes
import footerRoutes from "footer.routes";
import routes from "routes";

//Components
import { Box, Card } from "@mui/material";

// Images
import bgImage from "assets/images/nyc-skyline.jpg";

// Let's try to use as much raw MUI as we can, no wrapper or MK shit
function Home() {
  return (
    <>
      <DefaultNavbar routes={routes} sticky />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center"
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <MKTypography
              variant="h1"
              color="white"
              mt={-6}
              mb={1}
              textAlign="center"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"]
                }
              })}
            >
              Find your perfect sublet.{" "}
              <span style={{ fontStyle: "italic" }}>Fast.</span>{" "}
            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              textAlign="center"
              px={{ xs: 6, lg: 12 }}
              mt={1}
            >
              Let us scour Facebook for your ideal sublet, so you don't have to.
            </MKTypography>
            <Box sx={{ padding: "8px" }}>
              <MKButton variant="contained" href="/ChatHome" color="primary">
                Try Demo
              </MKButton>
            </Box>
            {/* </MKBadgeRoot> */}
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          // pt: 10,
          // p: 2,
          p: 2,
          pt: 5,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) =>
            rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl
        }}
      >
        <MKTypography variant="h1" gutterBottom textAlign="center">
          Don't waste time scrolling.
        </MKTypography>
        <MKTypography variant="h4" gutterBottom textAlign="center" mb={4}>
          Tell us what you're looking for, and we'll find that needle in the
          haystack in <span style={{ fontStyle: "italic" }}> seconds.</span>{" "}
        </MKTypography>
        <Counters />
        <Box
          sx={{ display: "flex", justifyContent: "center", maxWidth: "100%" }}
        >
          <MovieClip></MovieClip>
        </Box>
      </Card>

      <MKBox pt={6} px={1} mt={6} pb={6}>
        <SimpleFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Home;
