// src/components/RentalCard.tsx
import BathtubIcon from "@mui/icons-material/Bathtub";
import BedIcon from "@mui/icons-material/Bed";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Typography
} from "@mui/material";
import React from "react";
import { MessageDto } from "../models/MessageDto";

interface RentalCardProps {
  messageDTO: MessageDto;
}

const RentalCard: React.FC<RentalCardProps> = ({ messageDTO }) => {
  return (
    <Card sx={{ maxWidth: "80%", m: 2, overflow: "visible" }}>
      <CardContent>
        <Typography
          gutterBottom
          variant="h6"
          component="div"
          sx={{ fontWeight: "bold" }}
        >
          {messageDTO.location && `Location: ${messageDTO.location}`}
        </Typography>
        {messageDTO.url && (
          <CardActions>
            <Button
              size="small"
              href={messageDTO.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              View Post
            </Button>
          </CardActions>
        )}
        <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
          {messageDTO.author && (
            <>
              <strong>Author:</strong> {messageDTO.author}
              <br />
            </>
          )}
          {messageDTO.price && (
            <>
              <strong>Price:</strong> ${messageDTO.price}
              <br />
            </>
          )}
          {messageDTO.bed_count && (
            <Grid container alignItems="center">
              <BedIcon sx={{ mr: 1 }} />
              <strong>Beds:</strong> {messageDTO.bed_count}
            </Grid>
          )}
          {messageDTO.bath_count && (
            <Grid container alignItems="center">
              <BathtubIcon sx={{ mr: 1 }} />
              <strong>Baths:</strong> {messageDTO.bath_count}
            </Grid>
          )}
          {messageDTO.availability && (
            <>
              <strong>Availability:</strong> {messageDTO.availability}
              <br />
            </>
          )}
          {messageDTO.pros && (
            <>
              <strong>Pros:</strong> {messageDTO.pros}
              <br />
            </>
          )}
          {messageDTO.cons && (
            <>
              <strong>Cons:</strong> {messageDTO.cons}
            </>
          )}
        </Typography>
      </CardContent>
      {messageDTO.images && messageDTO.images.length > 0 && (
        <CardActions>
          <Grid container spacing={2} justifyContent="center">
            {messageDTO.images.map((image, index) => (
              <Grid item xs={4} key={index}>
                <CardMedia
                  component="img"
                  image={image}
                  alt={`Rental Image ${index + 1}`}
                  sx={{ width: 100, height: 100, objectFit: "cover" }}
                />
              </Grid>
            ))}
          </Grid>
        </CardActions>
      )}
    </Card>
  );
};

export default RentalCard;
