export class MessageDto {
  isUser: boolean;
  content: string;
  url?: string;
  author?: string;
  images?: string[];
  bed_count?: number;
  bath_count?: number;
  price?: number;
  pros?: string;
  cons?: string;
  availability?: string;
  location?: string;

  constructor(
    isUser: boolean,
    content: string,
    url?: string,
    author?: string,
    images?: string[],
    bed_count?: number,
    bath_count?: number,
    price?: number,
    pros?: string,
    cons?: string,
    availability?: string,
    location?: string
  ) {
    this.isUser = isUser;
    this.content = content;
    this.url = url;
    this.author = author;
    this.images = images;
    this.bed_count = bed_count;
    this.bath_count = bath_count;
    this.price = price;
    this.pros = pros;
    this.cons = cons;
    this.availability = availability;
    this.location = location;
  }
}
