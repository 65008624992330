import React from "react";
import YouTube from "react-youtube";

class MovieClip extends React.Component {
  render() {
    const options = {
      // height: "390",
      width: "100%", // Set to 100% to allow responsiveness
      playerVars: {
        autoplay: 0, // Autoplay should be 0 if you want to start paused
        controls: 1
      }
    };

    // Additional styles for responsiveness
    const videoStyles = {
      maxWidth: "90%",
      // minWidth: "640px",
      maxHeight: "100vh" // Optional to ensure the video is not taller than the viewport height
    };

    return (
      <div style={{ maxWidth: "100%", overflow: "hidden" }}>
        {" "}
        {/* Container to ensure content doesn't overflow */}
        <YouTube
          videoId="Q_UmGtpJdRI"
          opts={options} // 'opts' is the correct prop name instead of 'options'
          onReady={this._onReady}
          id="video"
          containerClassName="youtube-container" // Optional: for additional external styling
          style={videoStyles} // Apply the responsive styles
        />
      </div>
    );
  }

  _onReady(event) {
    // 'event.target.pauseVideo' might throw an error if 'pauseVideo' is not a function on 'event.target'
    // Ensure the YouTube Iframe API is loaded and the function exists
    if (event?.target?.pauseVideo) {
      event.target.pauseVideo();
    }
  }
}

export default MovieClip;
