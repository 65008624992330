// src/components/Chat.tsx
import SendIcon from "@mui/icons-material/Send";
import {
  Button,
  CircularProgress,
  Container,
  Grid,
  TextField,
  Typography
} from "@mui/material";
import OpenAI from "openai";
import React, { useEffect, useState } from "react";
import { MessageDto } from "../models/MessageDto";
import RentalCard from "./RentalCard";
const funMessages = [
  "Looking under every rock...",
  "Searching for the needle in the haystack...",
  "Brewing a fresh pot of coffee...",
  "Counting the stars in the sky...",
  "Triple-checking requirements...",
  "Finding Waldo...",
  "Herding all the cats...",
  "Trying my very best...",
  "Thinking of you...",
  "Distilling the essence of joy...",
  "Consulting with the crystal ball...",
  "Sorting socks by color...",
  "Dodging some scammers...",
  "Crunching some numbers...",
  "Making a great match...",
  "Hunting for the coziest corner...",
  "Swiping left on shady listings...",
  "Consulting the pigeons for leads...",
  "Decrypting hieroglyphic lease terms...",
  "Summoning the home spirits for guidance...",
  "Channeling my inner Sherlock for the ultimate find...",
  "Eavesdropping on neighborly gossip for leads...",
  "Sifting through the Internet jungle...",
  "Dusting off ancient rental maps...",
  "Bargaining with the Wi-Fi gods for patience...",
  "Nearing the home stretch...",
  "Taking a deep yawn...",
  "Munching some stale cookies...",
  "Comparing features...",
  "Counting the clouds..."
  // Add more messages if you like
];

const Chat: React.FC = () => {
  const [isWaiting, setIsWaiting] = useState<boolean>(false);
  const [funMessage, setFunMessage] = useState("");
  const [hasTimedOut, setHasTimedOut] = useState(false);

  const [messages, setMessages] = useState<Array<MessageDto>>(
    new Array<MessageDto>()
  );
  const [input, setInput] = useState<string>("");
  // const [assistant, setAssistant] = useState<any>(null);
  const [thread, setThread] = useState<any>(null);
  const [openai, setOpenai] = useState<any>(null);
  const [errorMessage, setErrorMessage] = useState<any>(null);
  useEffect(() => {
    initChatBot();
  }, []);

  useEffect(() => {
    let intervalId;
    if (isWaiting) {
      setFunMessage("Entering Sage Mode!"); // Set initial fun message
      let messageIndex = 0;
      intervalId = setInterval(() => {
        messageIndex =
          (messageIndex + Math.ceil(Math.random() * funMessages.length)) %
          funMessages.length;
        setFunMessage(funMessages[messageIndex]);
      }, 5000); // Change message every 5 seconds
    } else {
      setFunMessage("");
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [isWaiting]); // Only re-run the effect if isWaiting changes

  const initChatBot = async () => {
    const openai = new OpenAI({
      apiKey: process.env.REACT_APP_OPENAI_API_KEY,
      dangerouslyAllowBrowser: true
    });

    // Create a thread
    const thread = await openai.beta.threads.create();

    setOpenai(openai);
    // setAssistant(assistant);
    setThread(thread);
    setMessages([
      {
        content:
          "Hiii, let's find you a place to stay! I can help with NYC, SF, ATL, LA, or STL.",
        isUser: false
      }

      // {
      //   isUser: false,
      //   content: "",
      //   author: "Helen Weldeslassie",
      //   location: "Buckhead 960, near Lenox mall",
      //   price: 1073,
      //   availability: "ASAP, lease ends on November 1st, 2024",
      //   bed_count: 2,
      //   bath_count: 2,
      //   pros: "Private section of the apartment, can be furnished or unfurnished, great prior experience with no problems at the complex, superb location with a 3 minute walk to Lenox mall and a 15 minute drive to GSU and Emory",
      //   cons: "Utilities are an additional $60-80 a month",
      //   url: "https://www.facebook.com/groups/1499143016808484/user/100012524829421/?__cft__[0]=AZWAES6mMpX1iRWUXp2Qi4O8Z401kHvHncKr7kYPM-Lz3NuAemqfAGLyYF8KE7biFQoCiNFIKEsZPvEKjMSIM0Tu6fA8bx_LqGKNvNtk98OjgRdPO-PF_O6sJp2-PQLXuRIfCyOFF2Aud-dvP8cp7Dfx9ReHh9FA1WI3Phq4CfW930uKpMBFA_6QWXLiMTmkzR4&__tn__=%3C%2CP-R",
      //   images: [
      //     "https://scontent-sea1-1.xx.fbcdn.net/v/t39.30808-6/420019373_1762504307510380_2521101270907541427_n.jpg?stp=cp6_dst-jpg&_nc_cat=103&ccb=1-7&_nc_sid=c42490&_nc_ohc=OcfKDNHPYjUAX9_KjFP&_nc_ht=scontent-sea1-1.xx&oh=00_AfDwP0dQ5i5Ijs_dywnWeIdQuDNFESpv1Vm-XlXwn3zPHA&oe=65D1FF94"
      //   ]
      // }
    ]);
  };

  const createNewMessage = (content: string, isUser: boolean) => {
    const newMessage = new MessageDto(isUser, content);
    return newMessage;
  };

  const handleSendMessage = async () => {
    setErrorMessage(null);
    messages.push(createNewMessage(input, true));
    setMessages([...messages]);
    setInput("");

    // Send a message to the thread
    await openai.beta.threads.messages.create(thread.id, {
      role: "user",
      content: input
    });

    const SUBLET_SAGE_ASSISTANT_ID = "asst_bXU6HOFecQP9g1LTQyKfGJBq";

    // Run the assistant
    const run = await openai.beta.threads.runs.create(thread.id, {
      assistant_id: SUBLET_SAGE_ASSISTANT_ID
    });

    // Create a response
    let response = await openai.beta.threads.runs.retrieve(thread.id, run.id);

    // Set a 30-second timeout to display the error message
    const timeoutId = setTimeout(() => {
      setHasTimedOut(true);
    }, 15000); // 10 seconds

    // Wait for the response to be ready
    while (response.status === "in_progress" || response.status === "queued") {
      console.log("waiting...");
      setIsWaiting(true);
      await new Promise((resolve) => setTimeout(resolve, 5000));
      // TODO can we wait for messages to come in one at a time instead of the whole run? or even stream??
      response = await openai.beta.threads.runs.retrieve(thread.id, run.id);
    }

    // If successful before timeout, clear the timeout
    clearTimeout(timeoutId);
    setHasTimedOut(false);
    setIsWaiting(false);

    // Get the messages for the thread
    const messageList = await openai.beta.threads.messages.list(thread.id);

    // Process each message for the current run
    const runMessages = messageList.data.filter(
      (message) => message.run_id === run.id && message.role === "assistant"
    );

    // console.log("run messes: " + JSON.stringify(runMessages, null, 2));

    runMessages.forEach((runMessage) => {
      // console.log("run mess: " + JSON.stringify(runMessage, null, 2);
      if (
        runMessage.content.length > 0 &&
        runMessage.content[0].hasOwnProperty("text")
      ) {
        const content = runMessage.content[0]["text"].value; // Get the message content
        // console.log("content: " + content);
        // Use regex to match content wrapped within ```json ... ```
        const jsonRegex = /```json([\s\S]*?)```/;
        const jsonMatch = content.match(jsonRegex);

        let textContent = content;

        if (jsonMatch) {
          // Extract the JSON content
          const jsonContent = jsonMatch[1];
          console.log("json content: " + content);

          // Remove the JSON block from the original content to separate the textContent
          textContent = content.replace(jsonRegex, "").trim();

          // Process the JSON content
          try {
            const recommendations = JSON.parse(jsonContent);

            recommendations.forEach((recommendation) => {
              const {
                author,
                location,
                price,
                availability,
                bed_count,
                bath_count,
                pros,
                cons,
                URL,
                image
              } = recommendation;
              setMessages((prevMessages) => [
                ...prevMessages,
                new MessageDto(
                  false,
                  "",
                  URL,
                  author,
                  image,
                  bed_count,
                  bath_count,
                  price,
                  pros,
                  cons,
                  availability,
                  location
                ) // Add the new message with an empty body and complete attributes
              ]);
            });
          } catch (e) {
            console.error("Failed to parse JSON response:", e);
            setErrorMessage(
              "OOPS! Failed to parse JSON response. Please try again."
            );
          }
        }

        // If there's any text content outside the JSON block, add it as a text message
        if (textContent) {
          console.log("text content: ", textContent);
          setMessages((prevMessages) => [
            ...prevMessages,
            new MessageDto(false, textContent) // Add the text content as a plain message
          ]);
        }
      }
    });
  };

  // detect enter key and send message
  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      handleSendMessage();
    }
  };

  // TODO test on mobile with app installed
  function isMobileDevice() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  }

  return (
    <Container>
      <Grid
        container
        maxWidth={"100%"}
        direction="column"
        spacing={2}
        paddingBottom={2}
      >
        {messages.map((message, index) => (
          <Grid
            item
            mt={1}
            alignSelf={message.isUser ? "flex-end" : "flex-start"}
            textAlign={message.isUser ? "right" : "left"}
            key={index}
          >
            {message.isUser ? (
              <Typography sx={{ fontWeight: "bold", color: "grey" }}>
                User
              </Typography>
            ) : (
              <Typography color={"red"} sx={{ fontWeight: "bold" }}>
                Sage
              </Typography>
            )}
            {message.content &&
              message.content.split("\n").map((line, lineIndex) => (
                // Render each line as a separate Typography component
                <Typography key={lineIndex} mb={3} sx={{ textIndent: "1em" }}>
                  {line}
                </Typography>
              ))}{" "}
            {message.author && <RentalCard messageDTO={message} />}
          </Grid>
        ))}
      </Grid>
      <Grid
        maxWidth={"100%"}
        container
        direction="row"
        paddingBottom={5}
        justifyContent={"space-between"}
      >
        {isWaiting && (
          <Typography
            variant="subtitle1"
            color="secondary"
            align="center"
            textAlign={"left"}
            style={{ margin: "10px 0" }}
          >
            {funMessage}
          </Typography>
        )}
        <Grid item sm={11} xs={9}>
          <TextField
            label="Tell me what you're looking for"
            variant="outlined"
            disabled={isWaiting}
            fullWidth
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={handleKeyPress}
          />
        </Grid>

        <Grid item sm={1} xs={3}>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            onClick={handleSendMessage}
            disabled={isWaiting}
          >
            {isWaiting && <CircularProgress size="1.80rem" color="inherit" />}
            {!isWaiting && <SendIcon fontSize="large" />}
          </Button>
        </Grid>
        {errorMessage && (
          <Typography color="error" variant="body2" mt={2}>
            {errorMessage}
          </Typography>
        )}
        {hasTimedOut && !errorMessage && (
          <Typography color="error" variant="body2" mt={2}>
            This will take 30 - 60 seconds. Good things come to those who wait
            :)
          </Typography>
        )}
      </Grid>
    </Container>
  );
};

export default Chat;
