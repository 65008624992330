import DefaultNavbar from "components/Navbars/DefaultNavbar";
import routes from "routes";
import Chat from "../components/Chat"; // Ensure the correct path is used here

const ChatHome = () => {
  return (
    <div className="App">
      <DefaultNavbar routes={routes} sticky />
      <main style={{ paddingTop: "15vh" }}>
        <Chat />
      </main>
    </div>
  );
};

export default ChatHome;
